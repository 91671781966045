import * as Sentry from '@sentry/nuxt';

const runtimeConfig = useRuntimeConfig();

if (runtimeConfig.public.sentry.environment == 'production' || runtimeConfig.public.sentry.environment == 'staging') {
  if (runtimeConfig.public.sentry.environment == 'staging') {
    console.log('Sentry is enabled for staging with DSN: ' + runtimeConfig.public.sentry.dsn);
  }
  Sentry.init({
    // If set up, you can use your runtime config here
    dsn: runtimeConfig.public.sentry.dsn,
    environment: runtimeConfig.public.sentry.environment,
  });
}
